import { DashboardLayout } from '@components/pages/layouts';
import { useRouter } from 'next/router';

const IndexPage = () => {
  const router = useRouter();
  router.replace('/shipments');
  return <></>;
};

IndexPage.getLayout = (page: JSX.Element) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default IndexPage;
